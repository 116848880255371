//
// officegen: List of PPTX shapes.
//
// Please refer to README.md for this module's documentations.
//
// NOTE:
// - Before changing this code please refer to the hacking the code section on README.md.
//
// Copyright {c} 2013;
//
// Permission is hereby granted, free of charge, to any person obtaining
// a copy of this software and associated documentation files {the
// 'Software'}, to deal in the Software without restriction, including
// without limitation the rights to use, copy, modify, merge, publish,
// distribute, sublicense, and/or sell copies of the Software, and to
// permit persons to whom the Software is furnished to do so, subject to
// the following conditions:
//
// The above copyright notice and this permission notice shall be
// included in all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED 'AS IS': WITHOUT WARRANTY OF ANY KIND,
// EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
// MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
// IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY
// CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT,
// TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
// SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
//

var gObjPptxShapes = {
    ACTION_BUTTON_BACK_OR_PREVIOUS: {
        displayName: "Action Button: Back or Previous",
        name: "actionButtonBackPrevious",
        avLst: {}
    },
    ACTION_BUTTON_BEGINNING: {
        displayName: "Action Button: Beginning",
        name: "actionButtonBeginning",
        avLst: {}
    },
    ACTION_BUTTON_CUSTOM: {
        displayName: "Action Button: Custom",
        name: "actionButtonBlank",
        avLst: {}
    },
    ACTION_BUTTON_DOCUMENT: {
        displayName: "Action Button: Document",
        name: "actionButtonDocument",
        avLst: {}
    },
    ACTION_BUTTON_END: {
        displayName: "Action Button: End",
        name: "actionButtonEnd",
        avLst: {}
    },
    ACTION_BUTTON_FORWARD_OR_NEXT: {
        displayName: "Action Button: Forward or Next",
        name: "actionButtonForwardNext",
        avLst: {}
    },
    ACTION_BUTTON_HELP: {
        displayName: "Action Button: Help",
        name: "actionButtonHelp",
        avLst: {}
    },
    ACTION_BUTTON_HOME: {
        displayName: "Action Button: Home",
        name: "actionButtonHome",
        avLst: {}
    },
    ACTION_BUTTON_INFORMATION: {
        displayName: "Action Button: Information",
        name: "actionButtonInformation",
        avLst: {}
    },
    ACTION_BUTTON_MOVIE: {
        displayName: "Action Button: Movie",
        name: "actionButtonMovie",
        avLst: {}
    },
    ACTION_BUTTON_RETURN: {
        displayName: "Action Button: Return",
        name: "actionButtonReturn",
        avLst: {}
    },
    ACTION_BUTTON_SOUND: {
        displayName: "Action Button: Sound",
        name: "actionButtonSound",
        avLst: {}
    },
    ARC: {
        displayName: "Arc",
        name: "arc",
        avLst: {
            adj1: 16200000,
            adj2: 0
        }
    },
    BALLOON: {
        displayName: "Rounded Rectangular Callout",
        name: "wedgeRoundRectCallout",
        avLst: {
            adj1: -20833,
            adj2: 62500,
            adj3: 16667
        }
    },
    BENT_ARROW: {
        displayName: "Bent Arrow",
        name: "bentArrow",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 43750
        }
    },
    BENT_UP_ARROW: {
        displayName: "Bent-Up Arrow",
        name: "bentUpArrow",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000
        }
    },
    BEVEL: {
        displayName: "Bevel",
        name: "bevel",
        avLst: {
            adj: 12500
        }
    },
    BLOCK_ARC: {
        displayName: "Block Arc",
        name: "blockArc",
        avLst: {
            adj1: 10800000,
            adj2: 0,
            adj3: 25000
        }
    },
    CAN: {
        displayName: "Can",
        name: "can",
        avLst: {
            adj: 25000
        }
    },
    CHART_PLUS: {
        displayName: "Chart Plus",
        name: "chartPlus",
        avLst: {}
    },
    CHART_STAR: {
        displayName: "Chart Star",
        name: "chartStar",
        avLst: {}
    },
    CHART_X: {
        displayName: "Chart X",
        name: "chartX",
        avLst: {}
    },
    CHEVRON: {
        displayName: "Chevron",
        name: "chevron",
        avLst: {
            adj: 50000
        }
    },
    CHORD: {
        displayName: "Chord",
        name: "chord",
        avLst: {
            adj1: 2700000,
            adj2: 16200000
        }
    },
    CIRCULAR_ARROW: {
        displayName: "Circular Arrow",
        name: "circularArrow",
        avLst: {
            adj1: 12500,
            adj2: 1142319,
            adj3: 20457681,
            adj4: 10800000,
            adj5: 12500
        }
    },
    CLOUD: {
        displayName: "Cloud",
        name: "cloud",
        avLst: {}
    },
    CLOUD_CALLOUT: {
        displayName: "Cloud Callout",
        name: "cloudCallout",
        avLst: {
            adj1: -20833,
            adj2: 62500
        }
    },
    CORNER: {
        displayName: "Corner",
        name: "corner",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    CORNER_TABS: {
        displayName: "Corner Tabs",
        name: "cornerTabs",
        avLst: {}
    },
    CROSS: {
        displayName: "Cross",
        name: "plus",
        avLst: {
            adj: 25000
        }
    },
    CUBE: {
        displayName: "Cube",
        name: "cube",
        avLst: {
            adj: 25000
        }
    },
    CURVED_DOWN_ARROW: {
        displayName: "Curved Down Arrow",
        name: "curvedDownArrow",
        avLst: {
            adj1: 25000,
            adj2: 50000,
            adj3: 25000
        }
    },
    CURVED_DOWN_RIBBON: {
        displayName: "Curved Down Ribbon",
        name: "ellipseRibbon",
        avLst: {
            adj1: 25000,
            adj2: 50000,
            adj3: 12500
        }
    },
    CURVED_LEFT_ARROW: {
        displayName: "Curved Left Arrow",
        name: "curvedLeftArrow",
        avLst: {
            adj1: 25000,
            adj2: 50000,
            adj3: 25000
        }
    },
    CURVED_RIGHT_ARROW: {
        displayName: "Curved Right Arrow",
        name: "curvedRightArrow",
        avLst: {
            adj1: 25000,
            adj2: 50000,
            adj3: 25000
        }
    },
    CURVED_UP_ARROW: {
        displayName: "Curved Up Arrow",
        name: "curvedUpArrow",
        avLst: {
            adj1: 25000,
            adj2: 50000,
            adj3: 25000
        }
    },
    CURVED_UP_RIBBON: {
        displayName: "Curved Up Ribbon",
        name: "ellipseRibbon2",
        avLst: {
            adj1: 25000,
            adj2: 50000,
            adj3: 12500
        }
    },
    DECAGON: {
        displayName: "Decagon",
        name: "decagon",
        avLst: {
            vf: 105146
        }
    },
    DIAGONAL_STRIPE: {
        displayName: "Diagonal Stripe",
        name: "diagStripe",
        avLst: {
            adj: 50000
        }
    },
    DIAMOND: {
        displayName: "Diamond",
        name: "diamond",
        avLst: {}
    },
    DODECAGON: {
        displayName: "Dodecagon",
        name: "dodecagon",
        avLst: {}
    },
    DONUT: {
        displayName: "Donut",
        name: "donut",
        avLst: {
            adj: 25000
        }
    },
    DOUBLE_BRACE: {
        displayName: "Double Brace",
        name: "bracePair",
        avLst: {
            adj: 8333
        }
    },
    DOUBLE_BRACKET: {
        displayName: "Double Bracket",
        name: "bracketPair",
        avLst: {
            adj: 16667
        }
    },
    DOUBLE_WAVE: {
        displayName: "Double Wave",
        name: "doubleWave",
        avLst: {
            adj1: 6250,
            adj2: 0
        }
    },
    DOWN_ARROW: {
        displayName: "Down Arrow",
        name: "downArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    DOWN_ARROW_CALLOUT: {
        displayName: "Down Arrow Callout",
        name: "downArrowCallout",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 64977
        }
    },
    DOWN_RIBBON: {
        displayName: "Down Ribbon",
        name: "ribbon",
        avLst: {
            adj1: 16667,
            adj2: 50000
        }
    },
    EXPLOSION1: {
        displayName: "Explosion",
        name: "irregularSeal1",
        avLst: {}
    },
    EXPLOSION2: {
        displayName: "Explosion",
        name: "irregularSeal2",
        avLst: {}
    },
    FLOWCHART_ALTERNATE_PROCESS: {
        displayName: "Alternate process",
        name: "flowChartAlternateProcess",
        avLst: {}
    },
    FLOWCHART_CARD: {
        displayName: "Card",
        name: "flowChartPunchedCard",
        avLst: {}
    },
    FLOWCHART_COLLATE: {
        displayName: "Collate",
        name: "flowChartCollate",
        avLst: {}
    },
    FLOWCHART_CONNECTOR: {
        displayName: "Connector",
        name: "flowChartConnector",
        avLst: {}
    },
    FLOWCHART_DATA: {
        displayName: "Data",
        name: "flowChartInputOutput",
        avLst: {}
    },
    FLOWCHART_DECISION: {
        displayName: "Decision",
        name: "flowChartDecision",
        avLst: {}
    },
    FLOWCHART_DELAY: {
        displayName: "Delay",
        name: "flowChartDelay",
        avLst: {}
    },
    FLOWCHART_DIRECT_ACCESS_STORAGE: {
        displayName: "Direct Access Storage",
        name: "flowChartMagneticDrum",
        avLst: {}
    },
    FLOWCHART_DISPLAY: {
        displayName: "Display",
        name: "flowChartDisplay",
        avLst: {}
    },
    FLOWCHART_DOCUMENT: {
        displayName: "Document",
        name: "flowChartDocument",
        avLst: {}
    },
    FLOWCHART_EXTRACT: {
        displayName: "Extract",
        name: "flowChartExtract",
        avLst: {}
    },
    FLOWCHART_INTERNAL_STORAGE: {
        displayName: "Internal Storage",
        name: "flowChartInternalStorage",
        avLst: {}
    },
    FLOWCHART_MAGNETIC_DISK: {
        displayName: "Magnetic Disk",
        name: "flowChartMagneticDisk",
        avLst: {}
    },
    FLOWCHART_MANUAL_INPUT: {
        displayName: "Manual Input",
        name: "flowChartManualInput",
        avLst: {}
    },
    FLOWCHART_MANUAL_OPERATION: {
        displayName: "Manual Operation",
        name: "flowChartManualOperation",
        avLst: {}
    },
    FLOWCHART_MERGE: {
        displayName: "Merge",
        name: "flowChartMerge",
        avLst: {}
    },
    FLOWCHART_MULTIDOCUMENT: {
        displayName: "Multidocument",
        name: "flowChartMultidocument",
        avLst: {}
    },
    FLOWCHART_OFFLINE_STORAGE: {
        displayName: "Offline Storage",
        name: "flowChartOfflineStorage",
        avLst: {}
    },
    FLOWCHART_OFFPAGE_CONNECTOR: {
        displayName: "Off-page Connector",
        name: "flowChartOffpageConnector",
        avLst: {}
    },
    FLOWCHART_OR: {
        displayName: "Or",
        name: "flowChartOr",
        avLst: {}
    },
    FLOWCHART_PREDEFINED_PROCESS: {
        displayName: "Predefined Process",
        name: "flowChartPredefinedProcess",
        avLst: {}
    },
    FLOWCHART_PREPARATION: {
        displayName: "Preparation",
        name: "flowChartPreparation",
        avLst: {}
    },
    FLOWCHART_PROCESS: {
        displayName: "Process",
        name: "flowChartProcess",
        avLst: {}
    },
    FLOWCHART_PUNCHED_TAPE: {
        displayName: "Punched Tape",
        name: "flowChartPunchedTape",
        avLst: {}
    },
    FLOWCHART_SEQUENTIAL_ACCESS_STORAGE: {
        displayName: "Sequential Access Storage",
        name: "flowChartMagneticTape",
        avLst: {}
    },
    FLOWCHART_SORT: {
        displayName: "Sort",
        name: "flowChartSort",
        avLst: {}
    },
    FLOWCHART_STORED_DATA: {
        displayName: "Stored Data",
        name: "flowChartOnlineStorage",
        avLst: {}
    },
    FLOWCHART_SUMMING_JUNCTION: {
        displayName: "Summing Junction",
        name: "flowChartSummingJunction",
        avLst: {}
    },
    FLOWCHART_TERMINATOR: {
        displayName: "Terminator",
        name: "flowChartTerminator",
        avLst: {}
    },
    FOLDED_CORNER: {
        displayName: "Folded Corner",
        name: "folderCorner",
        avLst: {}
    },
    FRAME: {
        displayName: "Frame",
        name: "frame",
        avLst: {
            adj1: 12500
        }
    },
    FUNNEL: {
        displayName: "Funnel",
        name: "funnel",
        avLst: {}
    },
    GEAR_6: {
        displayName: "Gear 6",
        name: "gear6",
        avLst: {
            adj1: 15000,
            adj2: 3526
        }
    },
    GEAR_9: {
        displayName: "Gear 9",
        name: "gear9",
        avLst: {
            adj1: 10000,
            adj2: 1763
        }
    },
    HALF_FRAME: {
        displayName: "Half Frame",
        name: "halfFrame",
        avLst: {
            adj1: 33333,
            adj2: 33333
        }
    },
    HEART: {
        displayName: "Heart",
        name: "heart",
        avLst: {}
    },
    HEPTAGON: {
        displayName: "Heptagon",
        name: "heptagon",
        avLst: {
            hf: 102572,
            vf: 105210
        }
    },
    HEXAGON: {
        displayName: "Hexagon",
        name: "hexagon",
        avLst: {
            adj: 25000,
            vf: 115470
        }
    },
    HORIZONTAL_SCROLL: {
        displayName: "Horizontal Scroll",
        name: "horizontalScroll",
        avLst: {
            adj: 12500
        }
    },
    ISOSCELES_TRIANGLE: {
        displayName: "Isosceles Triangle",
        name: "triangle",
        avLst: {
            adj: 50000
        }
    },
    LEFT_ARROW: {
        displayName: "Left Arrow",
        name: "leftArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    LEFT_ARROW_CALLOUT: {
        displayName: "Left Arrow Callout",
        name: "leftArrowCallout",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 64977
        }
    },
    LEFT_BRACE: {
        displayName: "Left Brace",
        name: "leftBrace",
        avLst: {
            adj1: 8333,
            adj2: 50000
        }
    },
    LEFT_BRACKET: {
        displayName: "Left Bracket",
        name: "leftBracket",
        avLst: {
            adj: 8333
        }
    },
    LEFT_CIRCULAR_ARROW: {
        displayName: "Left Circular Arrow",
        name: "leftCircularArrow",
        avLst: {
            adj1: 12500,
            adj2: -1142319,
            adj3: 1142319,
            adj4: 10800000,
            adj5: 12500
        }
    },
    LEFT_RIGHT_ARROW: {
        displayName: "Left-Right Arrow",
        name: "leftRightArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    LEFT_RIGHT_ARROW_CALLOUT: {
        displayName: "Left-Right Arrow Callout",
        name: "leftRightArrowCallout",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 48123
        }
    },
    LEFT_RIGHT_CIRCULAR_ARROW: {
        displayName: "Left Right Circular Arrow",
        name: "leftRightCircularArrow",
        avLst: {
            adj1: 12500,
            adj2: 1142319,
            adj3: 20457681,
            adj4: 11942319,
            adj5: 12500
        }
    },
    LEFT_RIGHT_RIBBON: {
        displayName: "Left Right Ribbon",
        name: "leftRightRibbon",
        avLst: {
            adj1: 50000,
            adj2: 50000,
            adj3: 16667
        }
    },
    LEFT_RIGHT_UP_ARROW: {
        displayName: "Left-Right-Up Arrow",
        name: "leftRightUpArrow",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000
        }
    },
    LEFT_UP_ARROW: {
        displayName: "Left-Up Arrow",
        name: "leftUpArrow",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000
        }
    },
    LIGHTNING_BOLT: {
        displayName: "Lightning Bolt",
        name: "lightningBolt",
        avLst: {}
    },
    LINE_CALLOUT_1: {
        displayName: "Line Callout 1",
        name: "borderCallout1",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 112500,
            adj4: -38333
        }
    },
    LINE_CALLOUT_1_ACCENT_BAR: {
        displayName: "Line Callout 1 {Accent Bar}",
        name: "accentCallout1",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 112500,
            adj4: -38333
        }
    },
    LINE_CALLOUT_1_BORDER_AND_ACCENT_BAR: {
        displayName: "Line Callout 1 {Border and Accent Bar}",
        name: "accentBorderCallout1",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 112500,
            adj4: -38333
        }
    },
    LINE_CALLOUT_1_NO_BORDER: {
        displayName: "Line Callout 1 {No Border}",
        name: "callout1",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 112500,
            adj4: -38333
        }
    },
    LINE_CALLOUT_2: {
        displayName: "Line Callout 2",
        name: "borderCallout2",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 112500,
            adj6: -46667
        }
    },
    LINE_CALLOUT_2_ACCENT_BAR: {
        displayName: "Line Callout 2 {Accent Bar}",
        name: "accentCallout2",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 112500,
            adj6: -46667
        }
    },
    LINE_CALLOUT_2_BORDER_AND_ACCENT_BAR: {
        displayName: "Line Callout 2 {Border and Accent Bar}",
        name: "accentBorderCallout2",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 112500,
            adj6: -46667
        }
    },
    LINE_CALLOUT_2_NO_BORDER: {
        displayName: "Line Callout 2 {No Border}",
        name: "callout2",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 112500,
            adj6: -46667
        }
    },
    LINE_CALLOUT_3: {
        displayName: "Line Callout 3",
        name: "borderCallout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_3_ACCENT_BAR: {
        displayName: "Line Callout 3 {Accent Bar}",
        name: "accentCallout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_3_BORDER_AND_ACCENT_BAR: {
        displayName: "Line Callout 3 {Border and Accent Bar}",
        name: "accentBorderCallout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_3_NO_BORDER: {
        displayName: "Line Callout 3 {No Border}",
        name: "callout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_4: {
        displayName: "Line Callout 3",
        name: "borderCallout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_4_ACCENT_BAR: {
        displayName: "Line Callout 3 {Accent Bar}",
        name: "accentCallout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_4_BORDER_AND_ACCENT_BAR: {
        displayName: "Line Callout 3 {Border and Accent Bar}",
        name: "accentBorderCallout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE_CALLOUT_4_NO_BORDER: {
        displayName: "Line Callout 3 {No Border}",
        name: "callout3",
        avLst: {
            adj1: 18750,
            adj2: -8333,
            adj3: 18750,
            adj4: -16667,
            adj5: 100000,
            adj6: -16667,
            adj7: 112963,
            adj8: -8333
        }
    },
    LINE: {
        displayName: "Line",
        name: "line",
        avLst: {}
    },
    LINE_INVERSE: {
        displayName: "Straight Connector",
        name: "lineInv",
        avLst: {}
    },
    MATH_DIVIDE: {
        displayName: "Division",
        name: "mathDivide",
        avLst: {
            adj1: 23520,
            adj2: 5880,
            adj3: 11760
        }
    },
    MATH_EQUAL: {
        displayName: "Equal",
        name: "mathEqual",
        avLst: {
            adj1: 23520,
            adj2: 11760
        }
    },
    MATH_MINUS: {
        displayName: "Minus",
        name: "mathMinus",
        avLst: {
            adj1: 23520
        }
    },
    MATH_MULTIPLY: {
        displayName: "Multiply",
        name: "mathMultiply",
        avLst: {
            adj1: 23520
        }
    },
    MATH_NOT_EQUAL: {
        displayName: "Not Equal",
        name: "mathNotEqual",
        avLst: {
            adj1: 23520,
            adj2: 6600000,
            adj3: 11760
        }
    },
    MATH_PLUS: {
        displayName: "Plus",
        name: "mathPlus",
        avLst: {
            adj1: 23520
        }
    },
    MOON: {
        displayName: "Moon",
        name: "moon",
        avLst: {
            adj: 50000
        }
    },
    NON_ISOSCELES_TRAPEZOID: {
        displayName: "Non-isosceles Trapezoid",
        name: "nonIsoscelesTrapezoid",
        avLst: {
            adj1: 25000,
            adj2: 25000
        }
    },
    NOTCHED_RIGHT_ARROW: {
        displayName: "Notched Right Arrow",
        name: "notchedRightArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    NO_SYMBOL: {
        displayName: '"No" symbol',
        name: "noSmoking",
        avLst: {
            adj: 18750
        }
    },
    OCTAGON: {
        displayName: "Octagon",
        name: "octagon",
        avLst: {
            adj: 29289
        }
    },
    OVAL: {
        displayName: "Oval",
        name: "ellipse",
        avLst: {}
    },
    OVAL_CALLOUT: {
        displayName: "Oval Callout",
        name: "wedgeEllipseCallout",
        avLst: {
            adj1: -20833,
            adj2: 62500
        }
    },
    PARALLELOGRAM: {
        displayName: "Parallelogram",
        name: "parallelogram",
        avLst: {
            adj: 25000
        }
    },
    PENTAGON: {
        displayName: "Pentagon",
        name: "homePlate",
        avLst: {
            adj: 50000
        }
    },
    PIE: {
        displayName: "Pie",
        name: "pie",
        avLst: {
            adj1: 0,
            adj2: 16200000
        }
    },
    PIE_WEDGE: {
        displayName: "Pie",
        name: "pieWedge",
        avLst: {}
    },
    PLAQUE: {
        displayName: "Plaque",
        name: "plaque",
        avLst: {
            adj: 16667
        }
    },
    PLAQUE_TABS: {
        displayName: "Plaque Tabs",
        name: "plaqueTabs",
        avLst: {}
    },
    QUAD_ARROW: {
        displayName: "Quad Arrow",
        name: "quadArrow",
        avLst: {
            adj1: 22500,
            adj2: 22500,
            adj3: 22500
        }
    },
    QUAD_ARROW_CALLOUT: {
        displayName: "Quad Arrow Callout",
        name: "quadArrowCallout",
        avLst: {
            adj1: 18515,
            adj2: 18515,
            adj3: 18515,
            adj4: 48123
        }
    },
    RECTANGLE: {
        displayName: "Rectangle",
        name: "rect",
        avLst: {}
    },
    RECTANGULAR_CALLOUT: {
        displayName: "Rectangular Callout",
        name: "wedgeRectCallout",
        avLst: {
            adj1: -20833,
            adj2: 62500
        }
    },
    REGULAR_PENTAGON: {
        displayName: "Regular Pentagon",
        name: "pentagon",
        avLst: {
            hf: 105146,
            vf: 110557
        }
    },
    RIGHT_ARROW: {
        displayName: "Right Arrow",
        name: "rightArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    RIGHT_ARROW_CALLOUT: {
        displayName: "Right Arrow Callout",
        name: "rightArrowCallout",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 64977
        }
    },
    RIGHT_BRACE: {
        displayName: "Right Brace",
        name: "rightBrace",
        avLst: {
            adj1: 8333,
            adj2: 50000
        }
    },
    RIGHT_BRACKET: {
        displayName: "Right Bracket",
        name: "rightBracket",
        avLst: {
            adj: 8333
        }
    },
    RIGHT_TRIANGLE: {
        displayName: "Right Triangle",
        name: "rtTriangle",
        avLst: {}
    },
    ROUNDED_RECTANGLE: {
        displayName: "Rounded Rectangle",
        name: "roundRect",
        avLst: {
            adj: 16667
        }
    },
    ROUNDED_RECTANGULAR_CALLOUT: {
        displayName: "Rounded Rectangular Callout",
        name: "wedgeRoundRectCallout",
        avLst: {
            adj1: -20833,
            adj2: 62500,
            adj3: 16667
        }
    },
    ROUND_1_RECTANGLE: {
        displayName: "Round Single Corner Rectangle",
        name: "round1Rect",
        avLst: {
            adj: 16667
        }
    },
    ROUND_2_DIAG_RECTANGLE: {
        displayName: "Round Diagonal Corner Rectangle",
        name: "round2DiagRect",
        avLst: {
            adj1: 16667,
            adj2: 0
        }
    },
    ROUND_2_SAME_RECTANGLE: {
        displayName: "Round Same Side Corner Rectangle",
        name: "round2SameRect",
        avLst: {
            adj1: 16667,
            adj2: 0
        }
    },
    SMILEY_FACE: {
        displayName: "Smiley Face",
        name: "smileyFace",
        avLst: {
            adj: 4653
        }
    },
    SNIP_1_RECTANGLE: {
        displayName: "Snip Single Corner Rectangle",
        name: "snip1Rect",
        avLst: {
            adj: 16667
        }
    },
    SNIP_2_DIAG_RECTANGLE: {
        displayName: "Snip Diagonal Corner Rectangle",
        name: "snip2DiagRect",
        avLst: {
            adj1: 0,
            adj2: 16667
        }
    },
    SNIP_2_SAME_RECTANGLE: {
        displayName: "Snip Same Side Corner Rectangle",
        name: "snip2SameRect",
        avLst: {
            adj1: 16667,
            adj2: 0
        }
    },
    SNIP_ROUND_RECTANGLE: {
        displayName: "Snip and Round Single Corner Rectangle",
        name: "snipRoundRect",
        avLst: {
            adj1: 16667,
            adj2: 16667
        }
    },
    SQUARE_TABS: {
        displayName: "Square Tabs",
        name: "squareTabs",
        avLst: {}
    },
    STAR_10_POINT: {
        displayName: "10-Point Star",
        name: "star10",
        avLst: {
            adj: 42533,
            hf: 105146
        }
    },
    STAR_12_POINT: {
        displayName: "12-Point Star",
        name: "star12",
        avLst: {
            adj: 37500
        }
    },
    STAR_16_POINT: {
        displayName: "16-Point Star",
        name: "star16",
        avLst: {
            adj: 37500
        }
    },
    STAR_24_POINT: {
        displayName: "24-Point Star",
        name: "star24",
        avLst: {
            adj: 37500
        }
    },
    STAR_32_POINT: {
        displayName: "32-Point Star",
        name: "star32",
        avLst: {
            adj: 37500
        }
    },
    STAR_4_POINT: {
        displayName: "4-Point Star",
        name: "star4",
        avLst: {
            adj: 12500
        }
    },
    STAR_5_POINT: {
        displayName: "5-Point Star",
        name: "star5",
        avLst: {
            adj: 19098,
            hf: 105146,
            vf: 110557
        }
    },
    STAR_6_POINT: {
        displayName: "6-Point Star",
        name: "star6",
        avLst: {
            adj: 28868,
            hf: 115470
        }
    },
    STAR_7_POINT: {
        displayName: "7-Point Star",
        name: "star7",
        avLst: {
            adj: 34601,
            hf: 102572,
            vf: 105210
        }
    },
    STAR_8_POINT: {
        displayName: "8-Point Star",
        name: "star8",
        avLst: {
            adj: 37500
        }
    },
    STRIPED_RIGHT_ARROW: {
        displayName: "Striped Right Arrow",
        name: "stripedRightArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    SUN: {
        displayName: "Sun",
        name: "sun",
        avLst: {
            adj: 25000
        }
    },
    SWOOSH_ARROW: {
        displayName: "Swoosh Arrow",
        name: "swooshArrow",
        avLst: {
            adj1: 25000,
            adj2: 16667
        }
    },
    TEAR: {
        displayName: "Teardrop",
        name: "teardrop",
        avLst: {
            adj: 100000
        }
    },
    TRAPEZOID: {
        displayName: "Trapezoid",
        name: "trapezoid",
        avLst: {
            adj: 25000
        }
    },
    UP_ARROW: {
        displayName: "Up Arrow",
        name: "upArrow",
        avLst: {}
    },
    UP_ARROW_CALLOUT: {
        displayName: "Up Arrow Callout",
        name: "upArrowCallout",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 64977
        }
    },
    UP_DOWN_ARROW: {
        displayName: "Up-Down Arrow",
        name: "upDownArrow",
        avLst: {
            adj1: 50000,
            adj2: 50000
        }
    },
    UP_DOWN_ARROW_CALLOUT: {
        displayName: "Up-Down Arrow Callout",
        name: "upDownArrowCallout",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 48123
        }
    },
    UP_RIBBON: {
        displayName: "Up Ribbon",
        name: "ribbon2",
        avLst: {
            adj1: 16667,
            adj2: 50000
        }
    },
    U_TURN_ARROW: {
        displayName: "U-Turn Arrow",
        name: "uturnArrow",
        avLst: {
            adj1: 25000,
            adj2: 25000,
            adj3: 25000,
            adj4: 43750,
            adj5: 75000
        }
    },
    VERTICAL_SCROLL: {
        displayName: "Vertical Scroll",
        name: "verticalScroll",
        avLst: {
            adj: 12500
        }
    },
    WAVE: {
        displayName: "Wave",
        name: "wave",
        avLst: {
            adj1: 12500,
            adj2: 0
        }
    }
};

if (typeof module !== "undefined" && module.exports) {
    module.exports = gObjPptxShapes;
}
