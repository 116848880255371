/*global $, window*/
/*
 * THIS FILE HAS BEEN MODIFIED BY BEAUTIFULAI
 * ORIGINAL: https://github.com/mindmup/editable-table/blob/master/mindmup-editabletable.js
 */
$.fn.editableTableWidget = function(options) {
    "use strict";
    return $(this).each(function() {
        var buildDefaultOptions = function() {
                var opts = $.extend(
                    {},
                    $.fn.editableTableWidget.defaultOptions
                );
                opts.editor = opts.editor.clone();
                return opts;
            },
            activeOptions = $.extend(buildDefaultOptions(), options),
            ARROW_LEFT = 37,
            ARROW_UP = 38,
            ARROW_RIGHT = 39,
            ARROW_DOWN = 40,
            ENTER = 13,
            ESC = 27,
            TAB = 9,
            DELETE = 8,
            element = $(this),
            editor = activeOptions.editor
                .css("position", "absolute")
                .hide()
                .appendTo(element.parent()),
            active,
            showEditor = function(select) {
                active = element.find("td:focus:not(.locked)");
                if (active.length) {
                    var val;
                    if (
                        active[0].childNodes.length > 0 &&
                        active[0].childNodes[0].nodeType == 3
                    ) {
                        val = active[0].childNodes[0].textContent;
                    } else {
                        val = "";
                    }
                    editor
                        .val(val)
                        .removeClass("error")
                        .show()
                        .offset(active.offset())
                        .css(active.css(activeOptions.cloneProperties))
                        .width(active.width())
                        .height(active.height())
                        .focus();
                    if (select) {
                        editor.select();
                    }
                    editor.data("cell", active);
                    // editor.css("pointer-events", "auto");
                }
            },
            setActiveText = function() {
                var text = editor.val(),
                    evt = $.Event("change"),
                    originalContent;
                if (active.text() === text || editor.hasClass("error")) {
                    return true;
                }
                originalContent = active.html();
                active.text(text).trigger(evt, text);
                if (evt.result === false) {
                    active.html(originalContent);
                }
            },
            movement = function(element, event) {
                //tespirit - Modified from the original file.
                var keycode = event.which; //tespirit - Modified from the original file.
                if (
                    keycode === ARROW_RIGHT ||
                    (!event.shiftKey && keycode === TAB)
                ) {
                    //tespirit - Modified from the original file.
                    return element.next("td:not(.locked)");
                } else if (
                    keycode === ARROW_LEFT ||
                    (event.shiftKey && keycode === TAB)
                ) {
                    //tespirit - Modified from the original file.
                    return element.prev("td:not(.locked)");
                } else if (keycode === ARROW_UP) {
                    return element
                        .parent()
                        .prev()
                        .children()
                        .eq(element.index())
                        .not(".locked");
                } else if (keycode === ARROW_DOWN) {
                    return element
                        .parent()
                        .next()
                        .children()
                        .eq(element.index())
                        .not(".locked");
                }
                return [];
            };
        this.showEditor = select => showEditor(select);
        editor
            .blur(function() {
                if (active) {
                    active.trigger("blurEditor");
                }
                setActiveText();
                editor.hide();
            })
            .keydown(function(e) {
                var possibleMove; //tespirit - Modified from the original file.
                if (e.which === ENTER) {
                    if (active) {
                        active.trigger("blurEditor");
                    }
                    setActiveText();
                    editor.hide();
                    active.focus();
                    e.preventDefault();
                    e.stopPropagation();
                } else if (e.which === ESC) {
                    editor.val(active.text());
                    e.preventDefault();
                    e.stopPropagation();
                    editor.hide();
                    active.focus();
                } else if (
                    this.selectionEnd - this.selectionStart ===
                    this.value.length
                ) {
                    possibleMove = movement(active, e); //tespirit - Modified from the original file.
                    if (possibleMove.length > 0) {
                        if (active) {
                            active.trigger("blurEditor");
                        }

                        possibleMove.focus();
                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else if (
                    e.which == ARROW_LEFT ||
                    e.which == ARROW_RIGHT ||
                    e.which == ARROW_UP ||
                    e.which == ARROW_DOWN ||
                    e.which == TAB
                ) {
                    setActiveText();
                    editor.hide();
                    active.focus();
                    e.preventDefault();
                    e.stopPropagation();
                    possibleMove = movement(active, e); //tespirit - Modified from the original file.
                    if (possibleMove.length > 0) {
                        if (active) {
                            active.trigger("blurEditor");
                        }
                        possibleMove.focus();
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
            })
            .on("input paste", function() {
                var evt = $.Event("validate");
                active.trigger(evt, editor.val());
                if (evt.result === false) {
                    editor.addClass("error");
                } else {
                    editor.removeClass("error");
                }
            });
        element
            .on("click keypress dblclick", showEditor)
            .css("cursor", "pointer")
            .keydown(function(e) {
                var prevent = true,
                    possibleMove = movement($(e.target), e);
                if (possibleMove.length > 0) {
                    if (active) {
                        active.trigger("blurEditor");
                    }
                    possibleMove.focus();
                } else if (e.which === ENTER) {
                    showEditor(false);
                } else if (e.which === 17 || e.which === 91 || e.which === 93) {
                    showEditor(true);
                    prevent = false;
                } else if (e.which === DELETE) {
                    active = element.find("td:focus:not(.locked)");
                    if (active) {
                        active.text("");
                        active.trigger("change");
                    }
                } else {
                    prevent = false;
                }
                if (prevent) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            });

        element.find("td").prop("tabindex", 1);

        $(window).on("resize", function() {
            if (editor.is(":visible")) {
                editor
                    .offset(active.offset())
                    .width(active.width())
                    .height(active.height());
            }
        });
    });
};
$.fn.editableTableWidget.defaultOptions = {
    cloneProperties: [
        "padding",
        "padding-top",
        "padding-bottom",
        "padding-left",
        "padding-right",
        "text-align",
        "font",
        "font-size",
        "font-family",
        "font-weight",
        "border",
        "border-top",
        "border-bottom",
        "border-left",
        "border-right"
    ],
    editor: $("<input>")
};
